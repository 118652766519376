import React, { useState } from "react";
import Switch from "react-switch";

export default function SwitchButton({ initialValue, onChange ,disabled}) {
  const [checked, setChecked] = useState(initialValue);

  const handleChange = (nextChecked) => {
    // setChecked(nextChecked);
    onChange(nextChecked);
  };

  return (
    <div className="example">
      <label>
        <Switch
          onChange={handleChange}
          checked={initialValue}
          className="react-switch"
          disabled={disabled}
        />
      </label>
    </div>
  );
}
