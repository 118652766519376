import React, { useEffect, useRef, useState } from "react";
import { Card, Table, Badge } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import { notifyError, notifyTopRight } from "../common/Toaster";
import {
  actionJob,
  getAllJobs,
  getReceipt,
} from "../../services/Jobs/JobsService";
import moment from "moment";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { CSVLink } from "react-csv";
export default function ReciptManagement(props) {
  const headers = [
    { label: "Receipt Url", key: "url" },
    { label: "Processing Time", key: "timeTaken" },
    { label: "Vendor Name", key: "vendorName" },
    { label: "Country", key: "country" },
    { label: "Invoice Total", key: "invoiceTotal" },
    { label: "Invoice Total Confidence", key: "invoiceTotalConfidence" },
    { label: "Total TAX", key: "totalTax" },
    { label: "Total Tax Confidence", key: "totalTaxConfidence" },
    { label: "Invoice Date", key: "invoiceDate" },
    { label: "Invoice Date Confidence", key: "invoiceDateConfidence" },
    { label: "Date Created", key: "createdAt" },
  ];
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListlenght] = useState(0);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dateRangePickerKey, setDateRangePickerKey] = useState(1);
  const [initialFetch, setInitialFetch] = useState(true);
  const [sort, setSort] = useState("");
  const [countrySort, setCountrySort] = useState("");
  const [nameSort, setNameSort] = useState("");

  const [limit, setLimit] = useState(10);
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const [selectedIds, setSelectedIds] = useState([]);
  const chackbox = document.querySelectorAll(".custom-checkbox input");
  const motherChackBox = document.querySelector("#checkbox1_exam_all");
  const mainCheckBox = useRef();
  console.log(mainCheckBox, "motherChackBox");

  const chackboxFun = (type) => {
    // console.log(motherChackBox, "type");

    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  function handleSelectedId(data) {
    // console.log(id," id select")
    const oldData = selectedIds.find((item) => {
      return item == data;
    });
    if (oldData === undefined) {
      setSelectedIds([...selectedIds, data]);
    } else {
      let updatedArr = selectedIds.filter((item) => item !== data);
      console.log(updatedArr, " id removed");
      setSelectedIds([...updatedArr]);
    }
    console.log(oldData, "new id......");
  }

  function handleAllId() {
    const allId = users?.map((item) => {
      return item;
    });
    if (mainCheckBox?.current?.checked) {
      setSelectedIds([...allId]);
    } else {
      setSelectedIds([]);
    }
  }

  console.log(selectedIds, "selected data");
  ///function to select date ////
  function onDateChange(...args) {
    console.log(args, "selected date");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(args[1] ? moment(args[1]).format("YYYY-MM-DD") : "");
  }
  ///function to fetch table data ///
  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getReceipt(
        currentPage,
        limit,
        search,
        startDate,
        endDate,
        sort,
        countrySort,
        nameSort
      );
      console.log(response?.data?.data);
      setUsers(response.data.data);
      const total = response.data.totalRecords;
      setPageCount(Math.ceil(total / limit));
      setListlenght(response.data.totalRecords);
      setInitialFetch(false);
      if (response.data.data < limit) {
        setCurrentPage(0);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };

  const onAction = async (id, actionType) => {
    setLoader(true);
    try {
      const response = await actionJob(id);
      actionType === true
        ? notifyTopRight("Disabled Successfully.")
        : notifyTopRight("Enabled Successfully.");

      getTableData();
      console.log(response);
    } catch (error) {
      console.log(error.response, "heloo");
      notifyError(error.response.data?.message);
    } finally {
      setLoader(false);
    }
  };
  function onReset() {
    setDateRangePickerKey((prev) => prev + 1);
    setStartDate("");
    setEndDate("");
  }

  useEffect(() => {
    getTableData();
  }, [currentPage, endDate, limit, sort, countrySort, nameSort]);

  let timeoutId;

  // Define a helper function for debouncing
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func.apply(null, args);
      }, delay);
    };
  };

  // Create a debounced version of the handleFetch function
  const debouncedHandleFetch = debounce(getTableData, 500); // Adjust the delay as needed

  useEffect(() => {
    if (initialFetch === false) {
      // Reset current page when search or status changes
      setCurrentPage(0);
      // Trigger the debounced handleFetch function when search or status changes
      debouncedHandleFetch();
      // Cleanup function
      return () => {
        // Clear any pending API calls if component unmounts or search/status changes again
        clearTimeout(timeoutId);
      };
    }
  }, [search]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Receipt List" motherMenu="Receipt" />
        <div className="d-flex justify-content-between align-items-center">
          <select
            className="form-control w-50"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          >
            <option hidden>Select Rows</option>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
          {selectedIds?.length > 0 && (
            <CSVLink
              filename={"Receipt-file.csv"}
              data={selectedIds}
              headers={headers}
            >
              <button className="btn btn-primary">
                Export <i class="flaticon-381-send"></i>
              </button>
            </CSVLink>
          )}
        </div>
      </div>
      <Card>
        <Card.Header className="d-block">
          

          <div className="d-flex justify-content-between align-items-center mb-2">
            <RangeDatePicker
              key={dateRangePickerKey}
              startText="Start"
              endText="End"
              startPlaceholder="Start Date"
              endPlaceholder="End Date"
              onChange={onDateChange}
              autoFocus
            />
            <div>
              <select
                className="form-control"
                value={sort}
                onChange={(e) => setSort(e.target.value)}
              >
                <option hidden>Sort</option>
                <option value={"asc"}>Ascending</option>
                <option value={"desc"}>Descending</option>
              </select>
            </div>
            {startDate && (
              <button
                type="button"
                className="btn btn-primary py-2"
                onClick={() => onReset()}
              >
                Reset Date
              </button>
            )}
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="col-5 p-0" style={{ flexGrow: 1 }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className="input-group border bg-white input-group-sm"
                  style={{ borderRadius: "8px" }}
                >
                  <input
                    type="text"
                    name="table_search"
                    className="form-control float-right border-0 search-input"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      className="btn btn-default"
                      onClick={getTableData}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead className="text-black">
                <tr>
                  <th className="width50">
                    <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                      <input
                        type="checkbox"
                        ref={mainCheckBox}
                        className="custom-control-input"
                        id="checkbox1_exam_all"
                        onChange={() => {
                          chackboxFun("all");
                          handleAllId();
                        }}
                        required=""
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="checkbox1_exam_all"
                      ></label>
                    </div>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <strong>
                      Company ID{" "}
                      {sort === "asc" ? (
                        <button
                          onClick={() => {
                            setSort("desc");
                            setNameSort("");
                            setCountrySort("");
                          }}
                          className="btn p-0"
                        >
                          {"▼"}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setSort("asc");
                            setNameSort("");
                            setCountrySort("");
                          }}
                          className="btn p-0"
                        >
                          {"▲"}
                        </button>
                      )}
                    </strong>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <strong>
                      country{" "}
                      {countrySort === "asc" ? (
                        <button
                          onClick={() => {
                            setCountrySort("desc");
                            setNameSort("");

                            setSort("");
                          }}
                          className="btn p-0"
                        >
                          {"▼"}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setCountrySort("asc");
                            setNameSort("");

                            setSort("");
                          }}
                          className="btn p-0"
                        >
                          {"▲"}
                        </button>
                      )}
                    </strong>
                  </th>
                  <th>
                    <strong>Receipt</strong>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <strong>
                      Processing Time{" "}
                      {sort === "asc" ? (
                        <button
                          onClick={() => {
                            setSort("desc");
                            setNameSort("");
                            setCountrySort("");
                          }}
                          className="btn p-0"
                        >
                          {"▼"}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setSort("asc");
                            setNameSort("");
                            setCountrySort("");
                          }}
                          className="btn p-0"
                        >
                          {"▲"}
                        </button>
                      )}
                    </strong>
                  </th>
                  <th>
                    <strong>vendor Tax Id</strong>
                  </th>{" "}
                  <th>
                    <strong>vendor Tax Id Confidence</strong>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <strong>
                      vendor Name{" "}
                      {nameSort === "asc" ? (
                        <button
                          onClick={() => {
                            setNameSort("desc");
                            setCountrySort("");
                            setSort("");
                          }}
                          className="btn p-0"
                        >
                          {"▼"}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setNameSort("asc");
                            setCountrySort("");
                            setSort("");
                          }}
                          className="btn p-0"
                        >
                          {"▲"}
                        </button>
                      )}
                    </strong>
                  </th>
                  <th>
                    <strong>vendor Name Confidence</strong>
                  </th>
                  <th>
                    <strong>items</strong>
                  </th>
                  <th>
                    <strong>currency</strong>
                  </th>
                  <th>
                    <strong>invoice Id</strong>
                  </th>
                  <th>
                    <strong>invoice Id Confidence</strong>
                  </th>
                  <th>
                    <strong>invoice Total</strong>
                  </th>
                  <th>
                    <strong>invoice Total Confidence</strong>
                  </th>
                  <th style={{ minWidth: "150px" }}>
                    <strong>
                      invoice Date{" "}
                      {sort === "asc" ? (
                        <button
                          onClick={() => {
                            setSort("desc");
                            setNameSort("");
                            setCountrySort("");
                          }}
                          className="btn p-0"
                        >
                          {"▼"}
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setSort("asc");
                            setNameSort("");
                            setCountrySort("");
                          }}
                          className="btn p-0"
                        >
                          {"▲"}
                        </button>
                      )}
                    </strong>
                  </th>
                  <th>
                    <strong>invoice Date Confidence</strong>
                  </th>
                  <th>
                    <strong>total Tax</strong>
                  </th>
                  <th>
                    <strong>total Tax Confidence</strong>
                  </th>
                  <th>
                    <strong>Tax Included</strong>
                  </th>
                  <th>
                    <strong>Date Created</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {users?.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={item._id}
                          checked={selectedIds.includes(item)}
                          onChange={() => {
                            chackboxFun();
                            handleSelectedId(item);
                          }}
                        />

                        <label
                          className="custom-control-label"
                          htmlFor={item._id}
                        ></label>
                      </div>
                    </td>

                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.companyId?.id}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.country}
                    </td>
                    <td>
                      <a
                        href={item?.url}
                        target="_blank"
                        onClick={(e) => e.stopPropagation()}
                      >
                        {item?.url?.substring(item?.url?.lastIndexOf("/") + 1)}
                      </a>
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.timeTaken.toFixed(2)} sec
                    </td>
                    <td>{item?.vendorTaxId ? item?.vendorTaxId : "--"}</td>
                    <td>
                      {item?.vendorTaxIdConfidence
                        ? item?.vendorTaxIdConfidence
                        : "--"}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.vendorName}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.vendorNameConfidence}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.items?.length}
                    </td>

                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.currency ? item?.currency : "--"}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.invoiceId ? item?.invoiceId : "--"}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.invoiceIdConfidence
                        ? item?.invoiceIdConfidence
                        : "--"}
                    </td>

                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      ${item?.invoiceTotal}
                    </td>

                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.invoiceTotalConfidence}
                    </td>

                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.invoiceDate
                        ? moment(item?.invoiceDate).format("DD/MM/YYYY")
                        : ""}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.invoiceDateConfidence}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      ${item?.totalTax}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.totalTaxConfidence}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {item?.taxInc?"Yes":"No"}
                    </td>
                    <td
                      onClick={(e) =>
                        props.history.push({
                          pathname: "/receipt-details",
                          state: item,
                        })
                      }
                      className="pointer"
                    >
                      {moment(item?.createdAt).format("DD/MM/YYYY")}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
          {users?.length === 0 && !loader ? (
            <div className="justify-content-center d-flex my-5 ">
              Sorry, Data Not Found!
            </div>
          ) : (
            ""
          )}
          {users?.length !== 0 && (
            <div className="card-footer clearfix">
              <div className="d-block d-sm-flex justify-content-between align-items-center">
                <div className="dataTables_info">
                  Showing {currentPage * limit + 1} to{" "}
                  {listLength > (currentPage + 1) * limit
                    ? (currentPage + 1) * limit
                    : listLength}{" "}
                  of {listLength} entries
                </div>
                <Pagination
                  pageCount={pageCount}
                  pageValue={currentPage}
                  setPage={setCurrentPage}
                />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
