import React, { useEffect, useMemo, useRef, useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { Card, Table } from "react-bootstrap";
import countryList from "react-select-country-list";
import Select from "react-select";
import { RegionDropdown } from "react-country-region-selector";
import { notifyError, notifyTopRight } from "../common/Toaster";
import {
  getTexData,
  postTexData,
  updateTaxData,
} from "../../services/Tax/TaxService";
import Spinner from "../common/Spinner";
import Pagination from "../common/Pagination";
import moment from "moment";
import SwitchBotton from "../components/ReactSwitch";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { CSVLink } from "react-csv";

import countryCurrencyMap, { getCountry } from "country-currency-map";
export default function ConfigTable() {
  const headers = [
    { label: "Country", key: "country" },
    { label: "Province", key: "province" },
    { label: "Country Tax Name", key: "taxName" },
    { label: "Regional Tax Name", key: "regionalTaxName" },
    { label: "STANDARD Rate", key: "rate" },
    { label: "PST-REGIONAL TAX", key: "regionalTax" },
    { label: "Active To", key: "date" },
    { label: "Tax Reg?", key: "enable" },
  ];
  let errorsObj = {
    country: "",
    countryCode: "",
    province: "",
    taxName: "",
    regionalTaxName: "",
    rate: "",
    regionalTax: "",
    date: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [loader, setLoader] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListLength] = useState(0);
  // const limit = 10;
  const [limit, setLimit] = useState(10);
  const [showBtn, setShowBtn] = useState(false);
  const [dateRangePickerKey, setDateRangePickerKey] = useState(1);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [sort, setSort] = useState("asc");
  const [countrySortDirection, setCountrySortDirection] = useState("asc");
  const [selectedIds, setSelectedIds] = useState([]);
  const [newEntries, setNewEntries] = useState([]);
  const chackbox = document.querySelectorAll(".custom-checkbox input");
  const motherChackBox = document.querySelector("#checkbox1_exam_all");
  const mainCheckBox = useRef();
  // console.log(mainCheckBox, "motherChackBox");

  const chackboxFun = (type) => {
    // console.log(motherChackBox, "type");

    for (let i = 0; i < chackbox.length; i++) {
      const element = chackbox[i];
      if (type === "all") {
        if (motherChackBox.checked) {
          element.checked = true;
        } else {
          element.checked = false;
        }
      } else {
        if (!element.checked) {
          motherChackBox.checked = false;
          break;
        } else {
          motherChackBox.checked = true;
        }
      }
    }
  };
  function handleSelectedId(data) {
    // console.log(id," id select")
    const oldData = selectedIds.find((item) => {
      return item == data;
    });
    if (oldData === undefined) {
      setSelectedIds([...selectedIds, data]);
    } else {
      let updatedArr = selectedIds.filter((item) => item !== data);
      console.log(updatedArr, " id removed");
      setSelectedIds([...updatedArr]);
    }
    console.log(oldData, "new id......");
  }

  function handleAllId() {
    const allId = formData?.map((item) => {
      return item;
    });
    if (mainCheckBox?.current?.checked) {
      setSelectedIds([...allId]);
    } else {
      setSelectedIds([]);
    }
  }
  const [formData, setFormData] = useState([
    {
      // _id: "",
      country: "",
      countryCode: "",
      currency: "",
      province: null,
      taxName: "",
      regionalTaxName: null,
      rate: 0,
      regionalTax: 0,
      date: "",
      enable: false,
      isEdit: true,
    },
  ]);
  const [editingIndex, setEditingIndex] = useState(null);

  // const options = useMemo(() => countryList().getData(), []);
  const options = useMemo(() => {
    const countryOptions = countryList().getData();
    // Add currency information to each country option
    return countryOptions.map((country) => {
      const currencyInfo = getCountry(country.label); // Retrieve currency info
      const currency = currencyInfo ? currencyInfo.currency : ""; // Extract currency
      // console.log(currency, "currency"); // Debugging: Log country code and currency
      return {
        ...country,
        currency: currency,
      };
    });
  }, []);

  // console.log(options, "options");

  const handleAddRow = () => {
    setFormData([
      {
        _id: new Date().getTime(),
        country: "",
        countryCode: "",
        currency: "",
        province: null,
        taxName: "",
        regionalTaxName: null,
        rate: 0,
        regionalTax: 0,
        date: "",
        enable: false,
        isEdit: true,
      },
      ...formData,
    ]);
    setShowBtn(true);
    // Add the newly created entry to newEntries state
    setNewEntries([
      {
        country: "",
        countryCode: "",
        currency: "",
        province: null,
        taxName: "",
        regionalTaxName: null,
        rate: 0,
        regionalTax: 0,
        date: "",
        enable: false,
        isEdit: true,
      },
      ...newEntries,
    ]);
  };
  // console.log(formData, "formData");
  const changeHandler = (value, index) => {
    const newFormData = [...formData];
    newFormData[index].country = value.label;
    newFormData[index].countryCode = value.value;
    newFormData[index].currency = value.currency;
    setFormData(newFormData);
    if (newEntries && newEntries[index]) {
      const updatedNewEntries = [...newEntries];
      updatedNewEntries[index].country = value.label;
      updatedNewEntries[index].countryCode = value.value;
      updatedNewEntries[index].currency = value.currency;
      setNewEntries(updatedNewEntries);
    }
  };

  const handleInputChangeData = (e, index, field) => {
    const { value } = e.target;
    const newFormData = [...formData];
    newFormData[index][field] = value;
    setFormData(newFormData);
    // Check if newEntries has been initialized and the index is within bounds
    if (newEntries && newEntries[index]) {
      const updatedNewEntries = [...newEntries];
      updatedNewEntries[index][field] = value;
      setNewEntries(updatedNewEntries);
    }
  };
  console.log(newEntries, "newEntries");

  async function handleSubmit() {
    console.log(newEntries, "newEntries before submit");
    let error = false;
    const errorObj = { ...errorsObj };
    const formDataWithoutId = newEntries.map(({ _id, ...rest }) => rest);
    formDataWithoutId.forEach((item, index) => {
      const rowErrors = { ...errorsObj }; // Initialize error object for each row

      if (item.country === "") {
        rowErrors.country = "This field is required";
        error = true;
      }

      if (item.taxName === "") {
        rowErrors.taxName = "This field is required";
        error = true;
      }

      if (item.rate < 0) {
        rowErrors.rate = "This field is required";
        error = true;
      }
      if (item.date === "") {
        rowErrors.date = "This field is required";
        error = true;
      }

      // Set errors for the current row
      errorObj[index] = rowErrors;
    });

    setErrors(errorObj);
    if (error) {
      return;
    }
    try {
      const response = await postTexData({ arr: formDataWithoutId });
      notifyTopRight("Successfully.");
      getTableData();

      console.log(response);
    } catch (error) {
      console.log(error.response, " error");
      notifyError(error.response.data?.message);
    }
  }

  const handleEdit = (index, value) => {
    setEditingIndex(index);
    const newFormData = [...formData];
    newFormData[index].isEdit = value;
    setFormData(newFormData);
  };

  const getTableData = async () => {
    setLoader(true);
    try {
      const response = await getTexData(
        currentPage,
        limit,
        startDate,
        endDate,
        sort
      );
      console.log(response.data.data);
      // setUsers(response.data.data);
      const newData = response.data.data.map((item) => ({
        _id: item?._id,
        country: item?.country,
        countryCode: item?.countryCode,
        currency: item?.currency,
        province: item?.province,
        taxName: item?.taxName,
        regionalTaxName: item?.regionalTaxName,
        rate: item?.rate,
        regionalTax: item?.regionalTax,
        date: moment(item?.date).format("YYYY-MM-DD"),
        enable: item?.enable,
        isEdit: false,
      }));
      setFormData(newData);
      // setFormData(newArray);
      const total = response.data?.totalRecords;
      setPageCount(Math.ceil(total / limit));
      setListLength(response.data?.totalRecords);
      if (response.data.data < limit) {
        setCurrentPage(0);
      }
    } catch (error) {
      console.log(error, "error");
    } finally {
      setLoader(false);
    }
  };
  const handleSwitchChange = (value, index) => {
    const newFormData = [...formData];
    newFormData[index].enable = value;
    setFormData(newFormData);
    if (newEntries && newEntries[index]) {
      const updatedNewEntries = [...newEntries];
      updatedNewEntries[index].enable = value;
      setNewEntries(updatedNewEntries);
    }
  };

  async function saveChanges(index, id) {
    const editedItem = formData[index];

    try {
      const response = await updateTaxData(editedItem, id);
      notifyTopRight("Created successfully.");
      getTableData();
      setEditingIndex(null);
      console.log(response);
    } catch (error) {
      console.log(error.response, " error");
      notifyError(error.response.data?.message);
    }
  }
  ///function to select date ////
  function onDateChange(...args) {
    console.log(args, "selected date");
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(args[1] ? moment(args[1]).format("YYYY-MM-DD") : "");
  }
  function onReset() {
    setDateRangePickerKey((prev) => prev + 1);
    setStartDate("");
    setEndDate("");
  }
  useEffect(() => {
    getTableData();
  }, [ endDate]);

  const [fullFormData, setFullFormData] = useState([]); // Store the entire dataset

  // Function to fetch the full dataset
  const fetchFullFormData = async () => {
    try {
      const response = await getTexData(0, 50, "", ""); // Assuming 0 is the starting page and listLength is the total number of records
      const fullData = response.data.data.map((item) => ({
        _id: item?._id,
        country: item?.country,
        countryCode: item?.countryCode,
        currency: item?.currency,
        province: item?.province,
        taxName: item?.taxName,
        regionalTaxName: item?.regionalTaxName,
        rate: item?.rate,
        regionalTax: item?.regionalTax,
        date: moment(item?.date).format("YYYY-MM-DD"),
        enable: item?.enable,
        isEdit: false,
      }));
      setFullFormData(fullData);
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchFullFormData(); // Fetch the full dataset when the component mounts
  }, []);

  // Function to sort the entire dataset
  const sortFullFormData = (direction) => {
    const sortedData = [...fullFormData].sort((a, b) => {
      const countryA = a.country.toLowerCase();
      const countryB = b.country.toLowerCase();
      if (direction === "asc") {
        return countryA.localeCompare(countryB);
      } else {
        return countryB.localeCompare(countryA);
      }
    });
    setFullFormData(sortedData);
    // Recalculate page count based on the sorted data
    setPageCount(Math.ceil(sortedData.length / limit));

    // Adjust current page to remain on the same relative page after sorting
    setCurrentPage(0); // Reset to the first page after sorting
  };
  // Use sorted data for rendering table rows
  // const tableData = useMemo(() => {
  //   // Apply pagination to the sorted data
  //   const startIndex = currentPage * limit;
  //   const endIndex = startIndex + limit;
  //   return fullFormData.slice(startIndex, endIndex);
  // }, [currentPage, limit, fullFormData]);

  
  useEffect(() => {
    // Apply pagination to the sorted data
    const startIndex = currentPage * limit;
    const endIndex = startIndex + limit;
    const tableData = fullFormData.slice(startIndex, endIndex);
    
    // Update formData with tableData
    setFormData(tableData);
  }, [currentPage, limit, fullFormData]);
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center">
        <PageTitle activeMenu="Tax Management" motherMenu="Tax Management" />
        <div className="d-flex justify-content-between align-items-center">
          <select
            className="form-control w-50"
            value={limit}
            onChange={(e) => setLimit(e.target.value)}
          >
            <option hidden>Select Rows</option>
            <option>10</option>
            <option>20</option>
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
          {selectedIds?.length > 0 && (
            <CSVLink
              filename={"Tax-file.csv"}
              data={selectedIds}
              headers={headers}
            >
              <button className="btn btn-primary">
                Export <i class="flaticon-381-send"></i>
              </button>
            </CSVLink>
          )}
        </div>
      </div>
      <div className="contact-name">
        <Card>
          <Card.Header>
            <RangeDatePicker
              key={dateRangePickerKey}
              startText="Start"
              endText="End"
              startPlaceholder="Start Date"
              endPlaceholder="End Date"
              onChange={onDateChange}
              autoFocus
            />

            <div className="text-right">
              <button
                className="btn btn-primary py-2 mr-2"
                onClick={handleAddRow}
              >
                Add +
              </button>
              {startDate && (
                <button
                  type="button"
                  className="btn btn-primary py-2"
                  onClick={() => onReset()}
                >
                  Reset Date
                </button>
              )}
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th className="width50">
                      <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                        <input
                          type="checkbox"
                          ref={mainCheckBox}
                          className="custom-control-input"
                          id="checkbox1_exam_all"
                          onChange={() => {
                            chackboxFun("all");
                            handleAllId();
                          }}
                          required=""
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="checkbox1_exam_all"
                        ></label>
                      </div>
                    </th>
                    <th>
                      <strong>
                        Country
                        {/* <div className="d-flex flex-column"> */}
                        {sort === "asc" ? (
                          <button
                            onClick={() => {
                              setSort("desc");
                              sortFullFormData("desc");
                              setEditingIndex(null);
                            }}
                            className="btn p-0 w-50"
                          >
                            {"▼"}
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setSort("asc");
                              sortFullFormData("asc");
                              setEditingIndex(null);
                            }}
                            className="btn p-0 w-50"
                          >
                            {"▲"}
                          </button>
                        )}
                        {/* </div> */}
                      </strong>
                    </th>
                    <th>
                      <strong>Province</strong>
                    </th>
                    <th>
                      <strong>Country Tax Name</strong>
                    </th>
                    <th>
                      <strong>Region Tax Name</strong>
                    </th>
                    <th>
                      <strong>Standard Rate</strong>
                    </th>
                    <th>
                      <strong>Pst-Regional Tax</strong>
                    </th>
                    <th>
                      <strong>Active To</strong>
                    </th>
                    <th>
                      <strong>Tax Reg?</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {formData.map((item, index) => (
                    <tr key={item?._id}>
                      <td>
                        <div className="custom-control custom-checkbox checkbox-success check-lg mr-3">
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id={item._id}
                            checked={selectedIds.includes(item)}
                            onChange={() => {
                              chackboxFun();
                              handleSelectedId(item);
                            }}
                          />

                          <label
                            className="custom-control-label"
                            htmlFor={item._id}
                          ></label>
                        </div>
                      </td>
                      <td style={{ minWidth: "250px" }}>
                        <Select
                          placeholder="Country"
                          options={options}
                          value={
                            item.country
                              ? options.find(
                                  (option) => option.label === item.country
                                )
                              : null
                          }
                          onChange={(value) => changeHandler(value, index)}
                          // isDisabled={
                          //   editingIndex !== index && item.country !== ""
                          // }
                          isDisabled={
                            !item?.isEdit &&
                            item.country &&
                            item.country.trim() !== ""
                          }
                        />
                        {errors[index]?.country && (
                          <div className="text-danger fs-12">
                            {errors[index]?.country}
                          </div>
                        )}
                      </td>
                      <td style={{ minWidth: "200px" }}>
                        <RegionDropdown
                          className="form-control"
                          country={item.country}
                          value={item.province}
                          onChange={(value) =>
                            handleInputChangeData(
                              { target: { value } },
                              index,
                              "province"
                            )
                          }
                          // disabled={
                          //   editingIndex !== index && item.province !== ""
                          // }
                          disabled={!item?.isEdit}
                        />
                        {errors[index]?.province && (
                          <div className="text-danger fs-12">
                            {errors[index]?.province}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.taxName}
                          onChange={(e) =>
                            handleInputChangeData(e, index, "taxName")
                          }
                          readOnly={!item?.isEdit && item.taxName !== ""}
                          // readOnly={editingIndex !== index}
                        />
                        {errors[index]?.taxName && (
                          <div className="text-danger fs-12">
                            {errors[index]?.taxName}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.regionalTaxName}
                          onChange={(e) =>
                            handleInputChangeData(e, index, "regionalTaxName")
                          }
                          readOnly={
                            !item?.isEdit &&
                            item.regionalTaxName &&
                            item.regionalTaxName.trim() !== ""
                          }
                        />
                        {errors[index]?.regionalTaxName && (
                          <div className="text-danger fs-12">
                            {errors[index]?.regionalTaxName}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.rate}
                          onChange={(e) =>
                            handleInputChangeData(e, index, "rate")
                          }
                          readOnly={!item?.isEdit && item.rate !== ""}
                        />
                        {errors[index]?.rate && (
                          <div className="text-danger fs-12">
                            {errors[index]?.rate}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="text"
                          className="form-control"
                          value={item.regionalTax}
                          onChange={(e) =>
                            handleInputChangeData(e, index, "regionalTax")
                          }
                          readOnly={!item?.isEdit && item.regionalTax !== ""}
                        />
                        {errors[index]?.regionalTax && (
                          <div className="text-danger fs-12">
                            {errors[index]?.regionalTax}
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          type="date"
                          className="form-control"
                          value={item.date}
                          onChange={(e) =>
                            handleInputChangeData(e, index, "date")
                          }
                          readOnly={!item?.isEdit && item.date !== ""}
                        />
                        {errors[index]?.date && (
                          <div className="text-danger fs-12">
                            {errors[index]?.date}
                          </div>
                        )}
                      </td>
                      <td>
                        <SwitchBotton
                          initialValue={item.enable}
                          onChange={(value) => handleSwitchChange(value, index)}
                          disabled={
                            !item?.isEdit

                            // item.enable !== ""
                          }
                        />
                      </td>
                      <td>
                        {item?.isEdit && editingIndex !== index ? (
                          ""
                        ) : (
                          <>
                            {item?.isEdit && editingIndex === index ? (
                              <button
                                type="button"
                                className="btn btn-primary fs-12 py-2 px-2"
                                onClick={() => saveChanges(index, item?._id)}
                              >
                                Save
                              </button>
                            ) : (
                              <i
                                className="flaticon-381-edit-1 pointer"
                                onClick={() => handleEdit(index, true)}
                              ></i>
                            )}
                          </>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {formData?.length === 0 && !loader ? (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            ) : (
              ""
            )}
            {formData?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-block d-sm-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * limit + 1} to{" "}
                    {listLength > (currentPage + 1) * limit
                      ? (currentPage + 1) * limit
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
          {showBtn && (
            <Card.Footer>
              <div className="text-right">
                {formData?.length > 0 && (
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSubmit}
                    // disabled={}
                  >
                    Submit
                  </button>
                )}
              </div>
            </Card.Footer>
          )}
        </Card>
      </div>
    </div>
  );
}
